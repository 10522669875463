<style scoped>
	.b-table>tbody>tr:nth-of-type(odd) {
		--bs-table-accent-bg: red !important;
		color: red !important;
	}
	.btn, .btn:hover, .btn:active, .btn:focus {
		background: transparent;
		color: #EB455F;
		outline: 0 none !important;
		box-shadow: none;
	}
	.btn.btn-custom-action {
		border: 0 none;
	}
</style>
<script>
	import Layout from "../../layouts/main";
	import PageHeader from "@/components/page-header";
	import appConfig from "@/app.config";
	import Multiselect from "vue-multiselect";

	export default {
		page: {
			title: "",
			meta: [
				{
					name: "description",
					content: appConfig.description,
				},
			],
		},
		components: {
			Layout,
			PageHeader,
			Multiselect,
		},
		data() {
			return {
				isReportTableBusy: false,
				excelDownloading: false,
				tableData: [],
				title: "Employee List",
				items: [
					{
						text: "View",
					},
					{
						text: "Employee List",
						active: true,
					},
				],
				corporateId: '',
				totalRows: 0,
				totalPages: 1,
				currentPage: 1,
				perPage: 10,
				pageOptions: [10, 25, 50, 100, 500],
				filter: null,
				filterOn: [],
				sortBy: "age",
				sortDesc: false,
				fields: [
					{
						key: "Action",
						tdClass: "align-center",
						sortable: false,
						thStyle: { backgroundColor: '#f6f6f6' }
					},
					{
						key: "employee_name",
						sortable: true,
						tdClass: "align-center",
						thStyle: { backgroundColor: '#f6f6f6' }
					},
					{
						key: "mobile",
						sortable: true,
						tdClass: "align-center",
						thStyle: { backgroundColor: '#f6f6f6' }
					},
					{
						key: "role",
						sortable: true,
						tdClass: "align-center",
						thStyle: { backgroundColor: '#f6f6f6' }
					},
					{
						key: "attendanceType",
						sortable: false,
						tdClass: "align-center",
						thStyle: { backgroundColor: '#f6f6f6' }
					},
					{
						key: "dob",
						sortable: false,
						tdClass: "align-center",
						thStyle: { backgroundColor: '#f6f6f6' }
					},
					{
						key: "created",
						sortable: false,
						tdClass: "align-center",
						thStyle: { backgroundColor: '#f6f6f6' }
					}
				],
				// attendance: "",
				// attendanceFilterOptions: [
				//     "Present",
				//     "In",
				//     "Absent",
				// ],
				dateType: "",
				daterange: [new Date(), new Date()],
				path: "",
				firstActive: "active",
				secondActive: "",
				thirdActive: "",
				totalPresent: 0,
				totalAbsent: 0,
				totalOnLeave: 0,
				totalEmployee: 0,
				selectedBranch: '',
				employeeFilterBranch: [],
			};
		},
		computed: {
			/**
			 * Total no. of records
			 */
			rows() {
				return this.totalRows;
			},
		},
		mounted() {
			// Set the initial number of items
			this.totalRows = this.tableData.length;
			this.pageSize = 10
			this.corporateId = localStorage.getItem('corporateId')
			this.getBranchList()
			// this.checkUserRights();
		},
		methods: {
			/**
			 * Search the table data with search input
			 */
			headers: function() {},
			getBranchList: function() {
				this.axios.post('corporate/get-branch', {
					corporateId: (this.corporateId !== undefined && this.corporateId !== null) ? this.corporateId : this.$storageData.corporateId,
				}).then(result => {
					this.employeeFilterBranch = result.data.data;
					this.selectedBranch = this.employeeFilterBranch[0]
					this.getAllEmployees()
				}).catch(error => {
					console.log(error)
				})
			},
			onFiltered(filteredItems) {
				// Trigger pagination to update the number of buttons/pages due to filtering
				this.totalRows = filteredItems.length;
				this.currentPage = 1;
			},
			getAllEmployees() {
				this.isReportTableBusy = true;
				this.axios.post("getAllEmployees", {
					'corpBranchId': this.selectedBranch._id,
					'corporateId': (this.corporateId !== undefined && this.corporateId !== null) ? this.corporateId : this.$storageData.corporateId,
					'page': this.currentPage,
					'pageSize': this.pageSize,
				}).then((response) => {
					this.isReportTableBusy = false
					this.tableData = response.data.data.EmployeeList
					this.totalRows = response.data.data.TotalEmployeeCount
					this.totalPages = response.data.data.totalPages
				}).catch((error) => {
					console.log(error)
				})
			},
			clearFilter() {
				this.daterange = [new Date(), new Date()];
				this.selectedBranch = this.employeeFilterBranch[0]
				this.getAllEmployees()
			},
			applyFilter() {
				// console.log(this.selectedBranch._id)
				this.getAllEmployees();
			},
			downloadSample() {
				this.excelDownloading = true;
				this.axios
					.post(
						"exportExcel",
						{
							exportData: this.tableData,
							page: "EmployeeList",
						},
						{
							responseType: "blob",
						}
					)
					.then((response) => {
						this.excelDownloading = false;
						const url = URL.createObjectURL(
							new Blob([response.data], {
								type: "application/vnd.ms-excel",
							})
						);
						const link = document.createElement("a");
						link.href = url;
						link.setAttribute("download", "employee-report.xlsx");
						document.body.appendChild(link);
						link.click();
					});
			},
			changePage: function(value) {
				this.currentPage = value
				this.getAllEmployees()
			},
			changePageSize: function(value) {
				this.pageSize = value
				this.getAllEmployees()
			},
			deleteCorporate: function(item) {
				console.log(item)
			},
			searchFilter: function(value) {
				console.log(value)
			}
		},
		middleware: "authentication",
	};
</script>
<template>
	<Layout>
		<PageHeader :items="items" />
		<div class="card" style="margin-top: -27px">
			<div class="card-body">
				<div class="row inner mb-2">
					<div class="col-md-4">
						<label for="branch">Select Branch</label>
						<multiselect id="branch" v-model="selectedBranch" :options="employeeFilterBranch" placeholder="Select Branch" :multiple="false" track-by="name" label="name" :allow-empty="false">
							<template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
						</multiselect>
					</div>
					<div class="col-md-2" style="width: auto;">
						<button class="btn btn-outline-primary mt-4" v-on:click="applyFilter()">Apply Filter</button>
					</div>
					<div class="col-md-2" style="width: auto;">
						<button class="btn btn-outline-danger mt-4" v-on:click="clearFilter()">Clear Filter</button>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-12">
				<div class="card">
					<div class="card-body">
						<div class="row mt-4 mb-4">
							<div class="col-sm-12 col-md-4" style="margin-bottom:15px;">
								<!-- <div role="group" class="btn-group">
									<button type="button" class="btn btn-outline-primary" @click="downloadSample()">
										<span>Download Report</span>
										<b-spinner v-if="excelDownloading" small type="grow"></b-spinner>
										<b v-if="excelDownloading">Loading...</b>
									</button>
								</div> -->
							</div>
							<div class="col-sm-12 col-md-4">
								<div id="tickets-table_length" class="dataTables_length">
									<label class="d-inline-flex align-items-center">
										<span>Show</span>
										<b-form-select
											style="margin-left:5px; margin-right:5px"
											v-model="perPage"
											size="sm"
											:options="pageOptions"
											@change="changePageSize" />
										<span>entries</span>
									</label>
								</div>
							</div>
							<!-- Search -->
							<div class="col-sm-12 col-md-4 row">
								<div id="tickets-table_filter" class="dataTables_filter text-md-end">
									<label class="d-inline-flex align-items-center">
										<span>Search:</span>
										<b-form-input
											v-model="filter"
											type="search"
											placeholder="Search..."
											@keyup="searchFilter(filter)"
											class="form-control form-control-sm ms-2" />
									</label>
								</div>
							</div>
							<!-- End search -->
						</div>
						<!-- Table -->
						<div class="table-responsive mb-0">
							<b-table id="employee-list" striped hover outlined bordered :items="tableData" :fields="fields"
								thead-class="bg-transparent" responsive="sm" :per-page="0"
								:current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
								:headers="headers" fixed-header :busy="isReportTableBusy" show-empty>
								<template #table-busy>
									<div class="text-center text-danger my-2">
										<b-spinner variant="primary" class="align-middle" type="grow"></b-spinner>
										<strong class="text-primary">Loading...</strong>
									</div>
								</template>
								<template v-slot:cell(Action)="data">
									<router-link :to="{ name: 'employee', params: { id: data.item.employee_id, type: 'edit' } }">
										<i class="uil uil-edit-alt" title="Edit" style="font-size: 19px;"></i>
									</router-link>
									<b-button class="btn-custom-action" variant="danger" @click="deleteCorporate(data.item.employee_id)">
										<i class="mdi mdi-delete"></i>
									</b-button>
								</template>
							</b-table>
						</div>
						<div class="row">
							<div class="col">
								<div class="dataTables_paginate paging_simple_numbers float-end">
									<ul class="pagination pagination-rounded mb-0">
										<!-- pagination -->
										<b-pagination
											aria-controls="employee-list"
											v-model="currentPage"
											:total-rows="rows"
											:per-page="perPage"
											@change="changePage" />
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</Layout>
</template>
<style scoped>
	.table> :not(caption)>*>* {
		padding: 5px 8px !important;
	}
</style>